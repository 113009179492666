import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', padding: '10px', fontSize: '14px', color: '#333' }}>
      ASMRキリスト教会 all rights reserved.
    </footer>
  );
}

export default Footer;
